@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200&family=Mukta&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');

.box-shadow {
  webkit-box-shadow: 0px 0px 32px 0px rgba(41, 41, 41, 0.027);
  -moz-box-shadow: 0px 0px 32px 0px rgba(41, 41, 41, 0.027);
  box-shadow: 0px 0px 32px 0px rgba(41, 41, 41, 0.14);
}

/* remove svg gap */
@media (max-width: 768px) {
  svg {
    margin-top: -1px;
    margin-bottom: -1px;
  }
}


* {
  margin: 0;
  color: #333;
  --westar:#d4cdc4;
  --slategrey:#;
  --vinrouge:#965456;
  --leather:#906652;
  --cello:#3f4f62;
  --bazaar:#927478;
  --rosybrown:#d4cdc482;
  --airforceblue:#5c8cac;
}

.navbar-toggler-icon {
  display: none;
}

body { 

font-family: 'Nunito Sans', sans-serif;
font-size:1.1rem;

}

.logo-text{
  font-family: 'Montserrat', sans-serif !important;
}

.title {
  font-family: 'Julius Sans One', sans-serif !important;
}

.font-md {
  font-size: 2rem !important;
}

.font-big {
  font-size: 2.5rem !important;
}

.font-bigger {
  font-size: 3.5rem !important;
}

.container-fluid {
  margin: 0;
  padding: 0;
}

/* navbar */

@media (min-width: 767px) {
  .custom-hamburger {
    display: none;
  }
}

@media (max-width: 767px) {
/*   .navbar-collapse {
    -webkit-transition: height 0.3s ease-in-out !important;
    -moz-transition: height 0.3s ease-in-out !important;
    -o-transition: height 0.3s ease-in-out !important;
    transition: height 0.3s ease-in-out!important ;
  }  */
  .show, .collapsing {
    height: 100vh !important;
  }

  .navbar a {
    font-size: 1.8rem !important;
   /*  margin-left: 20px !important; */
  }

  li{
    font-size:1.3rem;
  }

  .icons{
    font-size: 1.8rem !important;
  }
  li > span:last-child {
    padding-bottom: 5px;
  }
}


button.custom-hamburger {
  border:none;
  background-color: transparent;
}
.navbar a {
 /*  color: rgba(255, 255, 255, 0.341);  */
 font-family: 'Julius Sans One', sans-serif !important;
 color: #3f4f62;
 font-size: 20px;

 text-decoration: none;
 padding: 6px;
 margin-left: 40px;
}

/* .navbar a:focus {
  transition: 0.5s;
  color: var(--slategrey); 
}
 */
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

a.active{
  transition: 0.2s;
  color: #5c8cac;
  padding-bottom: 10px;
  /* color: rgba(255, 255, 255, 0.819); */
}

/* home page */

/* .margin-100 {
  margin-top:300px;
}
 */


.accordion-body {
  color: var(--cello);
}

.accordion-collapse.collapse.show {
  color: red;
}

/* copyright */
.copyright-txt {
  margin: 0px;
  color: #f3f4f570;
}

.arrow-down {
  animation-name: arrow-down;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes arrow-down {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(10px);
  }
}

li {
  font-size:1.3rem;
}

/* .msg-today:hover {
  color:red !;
  font-size: 3rem;
} */

/* showcase */
.showcase-content {
  margin-top:5rem;
}

/* locations page */
@media (min-width:767px){
  .amenities{
    margin-top:80px;
  }
}


.carousel-fade .carousel-item {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

@media (min-width: 1500px) {
  .carousel-fade {
    margin-left: 150px;
  }
}

@media (max-width: 1500px) {
  .carousel-fade {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
/* contact form */
.bg-primary{
  background-color: #80909fa6 !important;
}

.form-control {
  height: 36px;
  background: #fff;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  border-radius: 2px;
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 1.5rem !important;
}

.contact-form .form-control {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0;
}

.form-control:focus,.form-control:active {
  border-color: #33333381 !important;
}

.form-label {
  color: #000;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.submit-btn {
  font-size: 1.2rem;
} 

@media (max-width: 767px) {
  .submit-btn {
    padding:10px 140px;
    font-size: 1.5rem;
  } 
  .contact-call {
    display: flex !important;
  }
  
}

.contact-call {
  display: none;
}

#message {
  display: none;
  border: 1px solid #80909fb3;
  background-color:#80909f43;
}

.blue {
  color:var(--airforceblue) !important;
  font-size: 0.8rem !important;
}

/* FAQ */

.accordion-header{
  font-family: 'Julius Sans One', sans-serif !important;
}

.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #5c8cacad;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button::after {
  background-image: none;
  content:"↓";
  color:#5c8cac98;
  width:auto;
  height:auto;
  font-size:1.5rem;
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
  content:"↓";
  color:#ffffff;
  width:auto;
  height:auto;
}

.accordion-body {
  background-color: #5c8cac21;
}

.accordion-button:focus {
  box-shadow:none;
  border: 1px soild red;
}


/* Footer */

a {
  color:#333;
  text-decoration:none;
}

a:hover {
  transition: 0.5s;
  color:#333333ad;
}

#email-size{
  font-size: 1.1rem !important;
}

/* make conatct form not zoom in on mobile */
@media screen and (max-width: 767px) {
  input, textarea {
    font-size: 16px !important;
  }
  .showcase-content {
    margin-top:0px;
  }

  .footer-li {
    font-size: 1.7rem;
  }
}